:root {
    --always-white: 255,255,255;
    --always-black: 0,0,0;
    --base-border-radius: 4px;

    // Start of light/standard theme
    --background-1: #fff;
    --background-2: #dedede;
    --background-3: #b6b6b6;
    --background-9: #3c3c3c;
    --text-0: #fff;
    --text-1: #fff;
    --text-3: #d7d7d7;
    --text-9: #3c3c3c;
    --text-10: #202020;
    --highlight-glow: #e8de53;
    
    --positive-action: rgb(51 132 10);
    --shadow-callout: rgb(120 104 238);
    
    --timeslots-bg: #3c3c3c;
    --timeslots-header-text: #fff;
    
    --timeslots-text: var(--text-9);
    --timeslots-badge-text: #fff;
    --timeslot-date-bg: rgb(255 255 255 / 0.5);
    --timeslot-1: #e2f3f3;
    --timeslot-3: #bcd8e5;
    --timeslot-4: #adcdcd;
    --timeslot-6: #507a8d;
    --timeslot-7: #3d606f;
    --timeslot-highlight-header: var(--timeslot-6);
    --timeslot-highlight-header-text: var(--timeslots-header-text);
    
    --link-color: #693b30;
    --section-header-bg: #3c3c3c;
    --section-header-text: #fff;
    --highlight-bg: #f5f3e7;
    --highlight-quote-bg: #f5f0e1;
    --main-header-text: #dbdbdb;
    --main-header-bg: #0b0b37;
    
    --weird-accent-color: #693b30;
    
    --video-thumb-vignette: rgb(195 158 103 / 0.3);
    --video-thumb-text-overlay: #7b7b7b;
    
    --car-first-class: #ffd65a;
    --car-second-class: #33cfff;
    --car-class-text: var(--text-9);
    
    
    // Start of dark theme
    @media (prefers-color-scheme: dark) {
        --background-1: #212121;
        --background-2: #292929;    
        --background-3: #3a3a3a;
        --background-9: #fff;
        --text-0: #313131;
        --text-1: #3c3c3c;
        --text-3: #bdbdbd;
        --text-9: #cdcdcd;
        --text-10: #e9e9e9;
        --highlight-glow: #5eb5a9;
        
        --positive-action: rgb(92 173 27);
        --shadow-callout: rgb(120 104 238);
    
        --timeslots-bg: #191919;
        --timeslots-header-text: #cdcdcd;
    
        --timeslots-text: var(--text-9);;
        --timeslots-badge-text: #d9d9d9;
        --timeslot-date-bg: rgb(55 55 55 / 50%);
        --timeslot-1: #262929;
        --timeslot-3: #2f2f2f;
        --timeslot-4: #1a1a1a;
        --timeslot-6: #335867;
        --timeslot-7: #284755;
        --timeslot-highlight-header: var(--timeslot-6);
        --timeslot-highlight-header-text: var(--timeslots-header-text);
    
        --link-color: #4d99b9;
        --section-header-bg: #1c1c1c;
        --section-header-text: #cdcdcd;
        --highlight-bg: #1c1c1c;
        --highlight-quote-bg: #161616;
        --main-header-text: #dbdbdb;
        --main-header-bg: #0b0b37;
    
        --weird-accent-color: #346b82;;
    
        --video-thumb-vignette: rgb(18 23 57 / 30%);
        --video-thumb-text-overlay: #777777;
    
        --car-first-class: rgb(255 214 90 / 85%);
        --car-second-class: rgb(51 207 255 / 85%);
        --car-class-text: var(--text-0);
    }
}


// Breakpoints
$large-width: 1140px;
$medium-width: 760px;
$small-width: 431px;

$section-max-width: 1100px;
$text-content-max-width: 850px;
