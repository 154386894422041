@import '../../_global.scss';

.Timeslot {
    color: var(--timeslots-text);
    display: block;
    background-color: var(--timeslot-1);
    position: relative;
    padding: 0 1.3em;
    overflow: hidden;
    border-radius: 4px;

    &[data-highlight="true"] {
        z-index: 50;
        box-shadow: var(--highlight-glow) 0 0 3em, var(--highlight-glow) 0 0 0 1px;
        h3.label {
            background: var(--timeslot-highlight-header);
            color: var(--timeslot-highlight-header-text);
        }
    }

    @media (min-width: $medium-width) {
        padding: 0 .66em;
    }

    @media (min-width: $large-width) {
        padding: 0 .5em;
    }

    .badge {
        background-color: var(--timeslot-7);
        color: var(--timeslots-badge-text);
    }

    h3.label {
        margin: 0;
        font-weight: 700;
        white-space: nowrap;
        margin-left: -50%;
        margin-right: -50%;
        padding-left: 50%;
        background: var(--timeslot-3);
        line-height: 2.2em;
        font-size: 111%;
        border-bottom: solid 1px var(--timeslot-4);
        overflow: hidden;

        .emoji {
            text-shadow: 0 0 0.7em var(--shadow-callout);
        }
        
        @media (min-width: $medium-width) {
            font-size: 1.6vw;
        }

        @media (min-width: $large-width)  {
            text-align: center;
            font-size: 100%;
            padding-left: 0;
        }
    }

    .official-likelihood {
        font-size: 0.8em;
        display: block;
        height: 0;
        line-height: 0;
        
        @media (min-width: $medium-width) {
            text-align: center;
        }

        .badge {
            font-size: 85%;
            font-weight: 400;
            position: relative;
            top: -0.75em;
            line-height: 0.9;
        }
    }

    .timeslot-date {
        font-size: 6vw;
        font-weight: 700;
        margin-top: 0.33em;

        @media (min-width: $small-width)  {
            font-size: 3.05vw;
            white-space: nowrap;
        }

        @media (min-width: $medium-width)  {
            font-size: 1.75vw;
        }

        @media (min-width: $large-width)  {
            font-size: .95em;
        }

        .date-gmt, .date-local {
            line-height: 1;
            margin-bottom: 0.15em;
            margin-top: -0.33em;

            @media (min-width: $large-width)  {
                margin-bottom: .5em;
            }
        }

        .date-gmt {
            transform-origin: 0 50%;
            transform: scale(0.75);
        }

        .date-label {
            font-size: 0.6em;
            font-style: italic;
            font-weight: 600;
            position: relative;
            top: 0.3em;
            left: -0.25em;
            background-color: var(--timeslot-date-bg);
            display: inline-block;
            border-radius: 1em;
            padding: 0.35em 0.7em 0.5em 0.5em;
            z-index: 5;

            @media (min-width: $small-width)  {
                font-size: 0.73em;
            }

            @media (min-width: $large-width)  {
                font-size: 0.63em;
            }
        }
        .date-time {
            position: relative;
            z-index: 10;
        }

    }

    .timeslot-participation {
        margin-top: 1em;
        margin-bottom: 0.5em;
        font-size: 79%;

        @media (min-width: $medium-width)  {
            font-size: 100%;
        }
        
        @media (min-width: $large-width)  {
            .badge {
                font-size: 70%;
            }
        }
        
        display: flex;
        > div {
            flex: 8;
            &:first-child {
                flex: 1;
            }
        }

        .sof-combined {
            cursor: default;
        }
    }

    .timeslot-info {
        font-size: 85%;

        @media (min-width: $large-width)  {
            font-size: 13px;
        }
    }

}