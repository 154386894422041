@import '../../_global';

.Announcement {
    padding: 1.8em;
    border: solid 2px var(--timeslot-3);
    background: var(--timeslot-1);
    line-height: 1.2em;
    border-radius: var(--base-border-radius);
    margin-bottom: 1em;
    font-size: 0.9em;
    
    @media (min-width: $small-width)  {
        font-size: 1em;
    }

    &:last-child {
        margin-bottom: 0;
    }

    h3 {
        margin: 0;
        font-size: 0.95rem;
        
        @media (min-width: $small-width)  {
            font-size: 1rem;
        }
    }

    p {
        margin: 0 0 0.33em 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
}