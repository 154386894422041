@import '../../_global.scss';

.Setups {
    [data-class="first"],
    [data-class="second"] {
        color: var(--car-class-text);
        font-weight: 700;
    }
    [data-class="first"] {
        background-color: var(--car-first-class);
    }
    [data-class="second"] {
        background-color: var(--car-second-class);
    }

    .cars-grid {
        font-size: 80%;
        @media (min-width: $medium-width) {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 2em;
        }

        .setup-row {
            background-color: var(--background-2);
            padding: 1em;
            padding-bottom: 1.2em;
            border-radius: var(--base-border-radius);
        }
    }

    .no-setups {
        margin-bottom: 1.5em;
    }

    .round-container {
        border-bottom: solid 1px rgba(0,0,0,0.33);
        margin-top: 1em;
        margin-bottom: 1em;

        h3 {
            margin-bottom: 0.33em;
        }

        .week-prefix {
            display: none;
            @media (min-width: $medium-width) {
                display: inline;
            }    
        }

        ul, li {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        ul {
            margin: 0.5em 0 1.5em;
        }

        li {
            margin-bottom: 0.68em;
            line-height: 1em;

            a {
                display: block;
                margin-right: 0.5em;
                margin-top: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                line-height: 1.3;
            }

            .comment {
                font-style: italic;
                font-size: 0.75em;
                line-height: 1.2;
                display: block;
            }
        }

        .notes {
            margin-bottom: 0.33em;
        }
    }


    .download-zips {
        margin-top: 2em;
        ul, li {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        ul {
            margin-top: 0.5em;
        }
        li {
            display: inline-block;
            margin-right: 1em
        }
    }
}