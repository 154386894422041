@import '../../global.scss';

@keyframes shimmer1 {
    0% {
        background-size: 100% 102%;
    }
    100% {
        background-size: 100% 103%;
    }
}

@keyframes shimmer2 {
    0% {
        background-size: 101% 106%;
    }
    100% {
        background-size: 102% 108%;
    }
}

@keyframes shimmer3 {
    0% {
        background-size: 104% 106%;
    }
    100% {
        background-size: 106% 104%;
    }
}

@keyframes shimmer4 {
    0% {
        background-size: 103% 105%;
    }
    100% {
        background-size: 102% 103%;
    }
}

@keyframes shimmer5 {
    0% {
        background-size: 101% 105%;
    }
    100% {
        background-size: 102% 108%;
    }
}


.Broadcast {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (min-width: $small-width) {
        margin-bottom: 0;
    }

    h3 {
        font-size: 3.1vw;
        margin: 0;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        
        @media (min-width: $small-width) {
            font-size: 2.3vw;
        }
        
        @media (min-width: $medium-width) {
            font-size: 1.7vw;
        }
        
        @media (min-width: $large-width) {
            font-size: 100%;
        }
    }

    .upcoming-date-wrapper {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.6);

        .upcoming-date {
            flex: 1;
            display: block;
            font-weight: 400;
            font-size: 3vw;
            color: var(--video-thumb-text-overlay);
            font-family: monospace;

            @media (min-width: $small-width) {
                font-size: 2.5vw;
            }

            @media (min-width: $large-width) {
                font-size: 22px;
            }
        }
    }
    
    .yt-video {
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        max-width: 100%;
        background-color: rgb(var(--always-black));

        .thumb-text {
            position: absolute;
            z-index: 100;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            display: flex;
            align-items: center;
            text-align: center;
            
            > span {
                background-color: rgba(var(--always-white), 0.6);
                color: rgb(var(--always-black));
                font-size: 1.42em;
                font-weight: 700;
                line-height: 0.9;
                display: block;
                width: 100%;
                white-space: normal;
                padding: 0.33em 0;
                letter-spacing: 0.02em;
            }
        }
        
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            box-shadow: inset 0 0 9vw var(--video-thumb-vignette);
            transition: box-shadow 300ms ease;
        }
        
        a {
            min-width: 0;
            display: block;
            max-width: 100%;
            grid-template-columns: 1fr 1fr 1fr;
            filter: saturate(45%) hue-rotate(-10deg) contrast(120%);
            transition: filter 300ms ease;
        }
        
        .thumb {
            display: block;
            max-width: 100%;
            padding-bottom: 56.25%;
            background-position: center;
            background-size: cover;
            position: relative;

            &.no-url {
                filter: saturate(45%) hue-rotate(-10deg) contrast(120%);
                transition: filter 300ms ease;    
            }

            .glitch {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-size: 100% 100%;
                background-position: 50% 50%;
                mix-blend-mode: screen;
                opacity: 1;
                transition: opacity 300ms ease;

                &.glitch-1 {
                    background-image: url(../../../public/vhs1.jpg);
                    @media (min-width: $large-width) {
                        // animation: shimmer1 75ms infinite;
                    }
                }
                &.glitch-2 {
                    background-image: url(../../../public/vhs2.jpg);
                    @media (min-width: $large-width) {
                        // animation: shimmer2 70ms infinite;
                    }
                }
                &.glitch-3 {
                    background-image: url(../../../public/vhs3.jpg);
                    @media (min-width: $large-width) {
                        // animation: shimmer3 67ms infinite;
                    }
                    opacity: 0.4;
                }
                &.glitch-4 {
                    background-image: url(../../../public/vhs4.jpg);
                    @media (min-width: $large-width) {
                        // animation: shimmer4 80ms infinite;
                    }
                    opacity: 0.5;
                }
                &.glitch-5 {
                    background-image: url(../../../public/vhs5.jpg);
                    @media (min-width: $large-width) {
                        // animation: shimmer5 88ms infinite;
                    }
                }
            }
        }

        &:hover {
            a, .no-url {
                filter: saturate(90%) hue-rotate(-2deg) contrast(100%);
            }

            .glitch {
                opacity: 0.75;
            }
        }
    }
}